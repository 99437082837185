*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Causten';
  src: url('../public/assets/fonts/Causten-Medium.woff2') format('woff2'),
      url('../public/assets/fonts/Causten-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Causten';
  src: url('../public/assets/fonts/Causten-Regular.woff2') format('woff2'),
      url('../public/assets/fonts/Causten-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Causten Semi';
  src: url('../public/assets/fonts/Causten-SemiBold.woff2') format('woff2'),
      url('../public/assets/fonts/Causten-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Causten';
  src: url('../public/assets/fonts/Causten-Bold.woff2') format('woff2'),
      url('../public/assets/fonts/Causten-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  --body-font: 'Causten';
}
body {
  font-family: var(--body-font);
  font-size: 18px;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.w-100 {
  width: 100%;
}
h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
}
p {
  margin-bottom: 20px;
  line-height: 1.55;
}
img {
  max-width: 100%;
  height: auto;
}
.page-wrapper {
  background-color: #00022C;
  background-image: url(../public/assets/images/content-bg.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
header {
  padding: 20px 24px 20px 70px;
  background: url(../public/assets/images/header-bg.png) no-repeat center / cover;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 9;
  height: 88px;
}
.logo {
  width: 50%;
}
.header-right {
  width: 50%;
}
.white-btn {
  background: #fff;
  color: #131314;
  text-decoration: none;
  padding: 15px 24px;
  min-width: 174px;
  border-radius: 40px;
  position: relative;
  line-height: 1;
  font-weight: 600;
}
.with-arrow::after {
  content: "";
  background: url(../public/assets/images/arrow-black.svg) no-repeat right / cover;
  position: absolute;
  right: 24px;
  width: 18px;
  height: 14px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.language-swither select {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
  position: absolute;
  left: 0;
  padding-left: 30px;
}
.language-swither {
  margin-right: 20px;
  position: relative;
  min-width: 100px;
  min-height: 20px;
}
.language-swither::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  background: url(../public/assets/images/globe.svg) no-repeat center / cover;
  width: 22px;
  height: 22px;
}
.hero-section {
  padding: 50px 41px 50px 70px;
}
.hero-desc {
  width: 55%;
  color: #fff;
  padding-right: 108px;
  order: 1;
}
.hero-img {
  width: 45%;
  order: 2;
  text-align: right;
}
.hero-desc h1 {
  margin-bottom: 25px;
}
.hero-desc p {
  font-weight: 500;
}
.hero-desc .white-btn {
  min-width: 364px;
  display: inline-block;
  text-align: center;
  padding: 23px 24px;
  margin-top: 40px;
}
footer {
 background: url(../public/assets/images/footer-bg.png) no-repeat center / cover; 
 height: 88px;
 padding: 20px 70px;
 color: #fff;
 position: fixed;
 left: 0px;
 right: 0px;
 bottom: 0px;
}
.copyright {
  width: 40%;
  font-weight: 500;
  order: 1;
}
.footer-right {
  width: 60%;
  order: 2;
}
.app-links {
  padding-top: 15px;
}
.app-links > a {
  display: inline-block;
  margin-right: 18px;
}
.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 40px;
}
.footer-links a:first-child {
  margin-left: 0px;
}
.footer-links {
  margin-right: 20px;
}
.copyright p {
  margin-bottom: 0px;
  margin-right: 30px;
}
.copyright p:last-child {
  margin-right: 0px;
}
.page-wrapper.inner-page {
  background: none;
  display: inherit;
}
.page-wrapper.inner-page header {
  position: static;
}
.page-wrapper.inner-page footer {
  position: static;
}
.inner-banner {
  background: url(../public/assets/images/content-bg.jpg) no-repeat center;
  padding-bottom: 20%;
  position: relative;
}
.banner-desc {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
}
.container {
  max-width: 1240px;
  padding: 0px 20px;
  margin: 0 auto;
}
.pg-content {
  padding: 50px 0px;
  font-size: 16px;
}
.pg-content h1 {
  font-size: 30px;
}
.pg-content h2 {
  font-size: 28px;
}
.pg-content h3 {
  font-size: 24px;
}
.pg-content h4 {
  font-size: 22px;
}
.pg-content h5 {
  font-size: 20px;
}
.pg-content h6 {
  font-size: 18px;
}
.pg-content h1,
.pg-content h2,
.pg-content h3,
.pg-content h4,
.pg-content h5,
.pg-content h6 {
  margin-bottom: 15px;
}
.pg-content ul,
.pg-content ol {
  margin: 20px 0px 30px;
  padding: 0px 15px;
}
.pg-content li {
  display: block;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
  line-height: 1.55;
}
.pg-content li:last-child {
  margin-bottom: 0px;
}
.pg-content ul li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
}
.pg-content ol {
  list-style: none;
  counter-reset: my-awesome-counter;
}
.pg-content ol li {
  counter-increment: my-awesome-counter;
}
.pg-content ol li::before {
  content: counter(my-awesome-counter) ". ";
  position: absolute;
  left: 0px;
}
.policy-form {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease 0s;
}
.policy-form.form-open {
  opacity: 1;
  visibility: visible;
  transition: 0.3s all ease 0s;
}
.bg-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.form-box {
  position: relative;
  width: 500px;
  background: #fff;
  padding: 40px;
  border-radius: 5px;
}
.text-box input {
  height: 46px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #f5f5f5;
  padding: 0px 18px;
  font-size: 16px;
  font-family: var(--body-font);
}
.submit-btn {
  margin-top: 20px;
}
.submit-btn input {
  font-family: var(--body-font);
  width: 100%;
  height: 42px;
  font-weight: 500;
  background: #000;
  color: #fff;
  font-size: 16px;
  max-width: 150px;
  border: none;
}
.text-box label {
  margin-bottom: 8px;
  display: block;
}
.close-icon {
  position: absolute;
  right: -12px;
  font-size: 14px;
  cursor: pointer;
  top: -12px;
  background: #000;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-box #response {
    margin-top: 16px;
    color: #008000;
}

@media screen and (max-width: 1350px), 
       screen and (max-height: 850px) {
  body,
  .language-swither select {
    font-size: 15px;
  }
  .white-btn {
    padding: 12px 20px;
    min-width: 146px;
  }
  .with-arrow::after {
    width: 14px;
    height: 10px;
    margin-top: -2px;
  }
  header {
    padding: 16px 24px 16px 50px;
    height: 72px;
  }
  .language-swither {
    min-width: 90px;
  }
  h1 {
    font-size: 50px;
  }
  .hero-desc h1 {
    margin-bottom: 18px;
  }
  .hero-desc .white-btn {
    margin-top: 10px;
  }
  .hero-desc .white-btn {
    min-width: 220px;
    padding: 18px;
  }
  footer {
    padding: 12px 50px;
    height: 72px;
  }
  .footer-links {
    margin-right: 15px;
  }
  .app-links > a {
    margin-right: 12px;
  }
  .footer-links a {
    margin-left: 30px;
  }
  .app-links img {
    max-width: 110px;
  }
  .app-links {
    padding-top: 5px;
  }
  .hero-img img {
    max-height: calc(100vh - 180px);
  }
  .copyright p {
    margin-right: 25px;
  }
  .footer-right {
    width: 54%;
  }
  .copyright {
    width: 46%;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 36px;
  }
  .hero-desc {
    padding-right: 30px;
  }
  .hero-desc .white-btn {
    min-width: 190px;
    padding: 16px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 992px) {
  header {
    position: static;
    padding: 15px 20px;
    height: auto;
  }
  footer {
    position: static;
    height: auto;
    padding: 20px;
  }
  .page-wrapper {
    height: auto;
    display: inherit;
  }
  .language-swither {
    min-width: 80px;
  }
  .hero-section {
    padding: 50px;
  }
  .md-block {
    display: block;
  }
  .footer-right {
    width: 100%;
    justify-content: center;
  }
  .copyright {
    width: 100%;
    text-align: center;
    margin-top: 12px;
  }
  .copyright .d-flex {
    justify-content: center;
    margin: 0px 10px;
  }
}
@media screen and (max-width: 767px) {
  .sm-block {
    display: block;
  }
  .header-right .white-btn {
    min-width: inherit;
    font-size: 0px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .with-arrow::after {
    width: 14px;
    height: 10px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .language-swither {
    min-width: 60px;
  }
  body, .language-swither select {
    font-size: 14px;
  }
  .language-swither::before {
    width: 18px;
    height: 18px;
  }
  .language-swither select {
    padding-left: 24px;
  }
  .logo img {
    max-height: 26px;
  }
  header {
    padding: 12px 20px;
  }
  .hero-section {
    padding: 40px 20px;
  }
  .hero-img {
    width: 100%;
    margin-bottom: 20px;
  }
  .hero-desc {
    width: 100%;
    padding-right: 0px;
  }
  h1 {
    font-size: 28px;
  }
  .hero-desc h1 {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 15px;
  }
  .hero-desc .white-btn {
    min-width: 150px;
    padding: 14px;
    margin-top: 8px;
  }
  .footer-links {
    text-align: center;
    margin-bottom: 18px;
  }
  .footer-links a {
    display: block;
    margin: 0px 0px 10px 0px;
  }
  .app-links {
    margin: 0px;
  }
  .app-links a {
    margin: 0px 10px 0px 0px;
  }
  .copyright p {
    margin-right: 0px !important;
  }
  .footer-links {
    margin-right: 0px !important;
  }
  .inner-banner {
    padding-bottom: 170px;
  }
  .pg-content {
    font-size: 15px;
  }
  .pg-content h1 {
    font-size: 26px;
  }
  .pg-content h2 {
    font-size: 22px;
  }
  .pg-content h3 {
    font-size: 20px;
  }
  .pg-content h4 {
    font-size: 18px;
  }
  .pg-content h5 {
    font-size: 16px;
  }
  .pg-content h6 {
    font-size: 14px;
  }
  .pg-content h1,
  .pg-content h2,
  .pg-content h3,
  .pg-content h4,
  .pg-content h5,
  .pg-content h6 {
    margin-bottom: 10px;
  }
  .pg-content ul, .pg-content ol {
    margin: 20px 0px;
    padding: 0px 10px;
  }
  .pg-content li {
    margin-bottom: 8px;
  }
  .form-box {
    width: 80%;
    max-width: 300px;
    padding: 25px;
  }
}

@media screen and (min-width: 1700px) {
  .hero-desc h1 {
    font-size: 75px;
  }
  .hero-desc p {
    font-size: 24px;
  }
  .app-links img {
    width: 190px;
  }
  .hero-desc {
    padding-right: 50px;
    width: 50%;
  }
  .hero-img {
    width: 50%;
  }
  .hero-img img {
    width: 700px;
  }
  .copyright,
  .footer-links a {
    font-size: 22px;
  }
}


