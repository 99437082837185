/* Modal Overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Modal Heading */
.modal-content h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

/* Input Field */
input[type="email"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="email"]:focus {
  border-color: #007bff;
  outline: none;
}

.error {
  border-color: #dc3545;
}

.error-text {
  color: #dc3545;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: space-between;
}

.modal-actions button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Primary Button */
.modal-actions button:first-of-type {
  background-color: #007bff;
  color: white;
}

.modal-actions button:first-of-type:hover {
  background-color: #0056b3;
}

/* Cancel Button */
.modal-actions button:last-of-type {
  background-color: #6c757d;
  color: white;
}

.modal-actions button:last-of-type:hover {
  background-color: #5a6268;
}

.modal-actions button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
