.signup-box {
  display: flex;
  border: 1px solid white;
  height: 40px;
  width: 400px;
}

.email-input {
  width: 300px !important;
  height: 100% !important;
  background-color: transparent;
  color: white;
  border: none !important;
  font-size: 13px !important;
}

.email-input::placeholder {
  color: white; /* Placeholder text color */
}

.signup-button {
  width: 100px;
  color: black;
  border: none;
  font-size: 12px !important;
  font-weight: bold;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .signup-box {
    width: 300px;
  }
  .email-input {
    width: 200px !important;
    font-size: 12px !important;
  }

  .signup-button {
    color: black;
  }
}
